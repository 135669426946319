/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ICollections,
    LISTING_PAGE_URI,
    NOT_FOUND_PAGE_URI
} from "../../const";
import { useGlobalContext } from "../../context/Global";
import { Favorites } from "../../utils/favorites";
import LoadingScreen from "../LoadingScreen";
import { CartItem } from "../../utils/cartItems";
import { detectIncognito } from "detectincognitojs";
import { languages } from "../../const/languages";
/**
 * This component will set 3 values into the global context: the fullMenu, collection, and menu.
 * The only necessary condition is that they exists, otherwise is going to redirect to the listing page.
 */
export default function SetRestaurantInformation(props: { children: any }) {
    const {
        fullMenu,
        setFullMenu,
        setCollection,
        setMenu,
        setAllFavorites,
        setCartData,
        language,
        setLanguage,
        lastCheck,
        setLastCheck,
        onChangeLanguage
    } = useGlobalContext();
    const [loading, setLoading] = React.useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const {
        restaurant: restaurantId,
        schedule: collectionId,
        menu: menuId
    } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [isFetched, setIsFetched] = useState(false); // Track fetchData success

    const fetchData = async () => {
        function checkCookie() {
            const cookieEnabled = navigator.cookieEnabled;
            if (!cookieEnabled) {
                document.cookie = "testcookie";
                if (document.cookie.indexOf("testcookie") === -1) {
                    showCookieFail();
                }
            }
            return cookieEnabled || showCookieFail();
        }

        function showCookieFail() {
            navigate(NOT_FOUND_PAGE_URI);
        }

        try {
            const now = Date.now();
            if (now - lastCheck > 15000 || !lastCheck) {
                setLastCheck(now);
                setLoading(true);
            }

            const url = window.location.href;

            detectIncognito()
                .then((result) => {
                    if (!result.isPrivate && !url.includes("/web")) {
                        checkCookie();
                    }
                })
                .catch((error) => {
                    console.log("Error ->", error);
                    if (!url.includes("/web")) {
                        checkCookie();
                    }
                });

            const urlSearchParams = new URLSearchParams(window.location.search);
            const isLang = urlSearchParams.get("lang");
            let currentLanguage = "";
            if (isLang && isLang in languages) {
                //Ping S3 to check if the translations are ready
                const res = await fetch(
                    `${process.env.REACT_APP_BUCKET_URL}/${restaurantId}/compiled_${isLang}.json?no-cache=${now}`,
                    { method: "HEAD" }
                );
                if (res.ok) {
                    currentLanguage = `_${isLang}`;
                }
            } else {
                currentLanguage = "";
            }

            const response = await window.fetch(
                `${
                    process.env.REACT_APP_BUCKET_URL
                }/${restaurantId}/compiled${currentLanguage}.json?no-cache=${
                    lastCheck || now
                }`
            );

            if (response?.ok) {
                const data = await response?.json();

                onChangeLanguage(
                    isLang && isLang in languages
                        ? isLang
                        : data?.source_language
                );
                // let lang = data?.source_language;
                // if (lang === "en" && data?.identifier !== "mantamar") {
                //     lang = null;
                // }
                // const langResponse = await window.fetch(
                //     `${
                //         process.env.REACT_APP_BUCKET_URL
                //     }/${restaurantId}/compiled${
                //         lang ? `_${lang}` : ""
                //     }.json?no-cache=${lastCheck || now}`
                // );
                // const langData = await langResponse.json();
                setFullMenu && setFullMenu(data);

                try {
                    const favorites = new Favorites(data.identifier);
                    const cartItem = new CartItem(data.identifier);
                    setAllFavorites &&
                        setAllFavorites(favorites.getFavorites());
                    setCartData && setCartData(cartItem.getCartItem());
                } catch (e) {
                    console.log("error", e);
                }

                setIsFetched(true); // Mark fetchData as successful
            } else {
                navigate(LISTING_PAGE_URI);
            }

            setTimeout(() => {
                setLoading(false);
            }, 300);
        } catch (error) {
            console.log("Error ->", error);
        }
    };

    useEffect(() => {
        if (firstLoad) {
            fetchData();
            setFirstLoad(false);
        }
    }, [language]);

    const handleTracking = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isSession = urlSearchParams.get("session");

        const body = {
            record_uuid: fullMenu?.instance,
            action: "menu_view",
            note: isSession || ""
        };

        let attempt = 0;
        let success = false;

        while (attempt < 2 && !success) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_TRACK_URL}/cart/tracking`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(body)
                    }
                );

                const data = await response.json();

                if (response.ok && !data?.data?.code) {
                    success = true;
                } else {
                    console.warn(`Tracking failed. Attempt ${attempt + 1}`);
                }
            } catch (err) {
                console.warn(
                    `Tracking request failed. Attempt ${attempt + 1}`,
                    err
                );
            }

            attempt++;

            if (!success && attempt < 2) {
                await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay before retrying
            }
        }

        if (!success) {
            console.error("Tracking failed after retry.");
        }
    };

    useEffect(() => {
        // Call handleTracking only after fetchData is successfully completed
        if (isFetched && fullMenu?.instance) {
            handleTracking();
        }
    }, [isFetched, fullMenu?.instance]); // Run only when fetchData is completed successfully

    React.useEffect(() => {
        if (location.state?.prev && location.state?.prev !== restaurantId) {
            // Return to the default language when switching restaurants
            setLanguage("");
        }
        // Track the current restaurant to compare against the next visit
        location.state = { prev: location.pathname.split("/")[1] };

        function setCurrentCollection() {
            if (!collectionId) {
                return;
            }
            const currentCollection = fullMenu?.collection_links?.filter(
                (collection) => collection.collection_id._id === collectionId
            );
            if (!currentCollection || !currentCollection.length) {
                console.log(
                    "Current collection not found, should redirect to restaurant´s"
                );
                navigate(`/${fullMenu?.identifier}`);
            } else {
                setCollection && setCollection(currentCollection[0]);
                setCurrentMenu(currentCollection[0]);
            }
        }

        function setCurrentMenu(collection: { collection_id: ICollections }) {
            if (!menuId) {
                return;
            }
            const currentMenu = collection?.collection_id?.menu_links?.filter(
                (menu) => menu.menu_id._id === menuId
            );
            if (!currentMenu || !currentMenu.length) {
                console.log(
                    "Current menu not found, should redirect to collection´s"
                );
                navigate(
                    `/${fullMenu?.identifier}/${collection.collection_id._id}`
                );
            } else {
                setMenu && setMenu(currentMenu[0]);
                setLoading(false);
            }
        }

        !_.isEmpty(fullMenu) && setCurrentCollection();
    }, [collectionId, location, menuId, fullMenu, language]);

    return loading ? <LoadingScreen></LoadingScreen> : props.children;
}
