import React from "react";
import { Root, classes } from "./styles";
import { Grid } from "@mui/material";

export default function HealthCheck() {
    return (
        <Root>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src="/stellar-menus-for-white-bg.png"
                        alt="MilesCX Logo"
                    ></img>
                </Grid>
                <Grid item xs={11} sm={11} md={9} lg={8} xl={7}>
                    <h1 className={classes.title}>
                        "message": "All systems online"
                    </h1>
                </Grid>
            </Grid>
        </Root>
    );
}
